import { Box, Link } from 'theme-ui';
import Page from '../components/layout/Page';
import { ArrowRightIcon } from 'components/ui/icons';

export default function Custom404() {
  return (
    <Page>
      <Box variant="layout.content" sx={{ textAlign: 'center' }}>
        <Box
          as="h1"
          sx={{
            fontSize: [32, 48],
            fontWeight: 'bold',
            mt: [24, 48],
          }}
        >
          Page Not Found
        </Box>
        <Box
          sx={{
            maxWidth: 600,
            px: 16,
            mx: 'auto',
            mt: 24,
          }}
        >
          We're sorry, but the page you are looking for could not be found.
          Please try the links below or contact support:{' '}
          <Link href="mailto:hello@standardresume.co">
            hello@standardresume.co
          </Link>
          .
        </Box>
        <Box sx={{ mt: [24, 32], mb: [100, 200] }}>
          <PageLink url="/">Home</PageLink>
          <PageLink url="/remote-jobs">Remote Jobs</PageLink>
          <PageLink url={`${process.env.NEXT_PUBLIC_APP_URL}/login`}>
            Sign In
          </PageLink>
          <PageLink url="/frequently-asked-questions">FAQ</PageLink>
        </Box>
      </Box>
    </Page>
  );
}

type Props = {
  url: string;
  children: React.ReactNode;
};

function PageLink({ url, children }: Props) {
  return (
    <Link
      href={url}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 16,
        fontWeight: 'bold',
      }}
    >
      {children}
      <ArrowRightIcon ml={8} color="brandBlue" />
    </Link>
  );
}
